.ql-editor {
    min-height: 300px;
}

.xcloud-header {
    background-color: #ffffff !important;
    padding-left: 24px;
    box-shadow: 0 5px 10px -5px #dddddd;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    bottom: 0px;
}

.xcloud-header .logo {
    float: left;
    overflow: hidden;
    color: #000000d9;
    font-weight: 700;
    font-size: 18px;
    width: 200px;
    font-family: PuHuiTi, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, apple color emoji, segoe ui emoji, Segoe UI Symbol, noto color emoji, sans-serif;
    line-height: 64px;
    letter-spacing: -.18px;
    white-space: nowrap;
    text-decoration: none;
}

.xcloud-header .logo img {
    height: 36px;
    margin-right: 12px;
}

.xcloud-body {
    margin-top: 64px;
    background-color: #ffffff !important;
}

.xcloud-body .ant-layout-sider {
    background-color: #ffffff;
}

.xcloud-content-wrapper {
    height: calc(100vh - 64px);
    overflow: hidden;
    padding: 0px 18px 18px 18px;
    margin-left: 200px;
}

.xcloud-content-wrapper .breadcrumb {
    margin: 12px 0px;
}

.xcloud-content {
    background-color: #ffffff;
    padding: 12px;
    height: 100%;
    overflow: auto;
}



.market {
    padding: 10px 10px;
    position: relative;
}

.market .item .item-img {
    position: relative;
}

.market .item img {
    width: 100%;
}

.market .item .title {
    padding: 10px;
    position: relative;
}

.market .count {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 14px;
    color: #bbbbbb;
}

.market .item .title .main {
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.market .item .title .sub {
    font-size: 14px;
    color: #BBBBBB;
}



.market .item:hover .bg {
    display: flex;
}

.market .item .bg {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.6;
    display: none;
    background-color: rgba(0, 0, 0, 1);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.market .item .bg button {
    width: 112px;
    height: 36px;
    border-radius: 18px;
    color: rgba(255, 255, 255, 100);
    background-color: rgba(0, 0, 0, 0);
}

.market .item .bg button:hover {
    background-color: rgba(255, 255, 255, 1);
    color: #4D4D4D;
}


.job-log-table .ant-table-row {
    cursor: pointer;
}

.CodeMirror {
    height: 400px !important;
}

#id-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #b1afafbf;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 20px;
}


.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.flex-row-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.flex-row-reverse-right {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;
}